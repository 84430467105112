import React from "react";
import { NavLink } from "react-router-dom";
import "../../css/FullMenu.css";

const FullMenu = ({ navbarContent, color, active }) => {
  if (active) {
    color = "black";
  }
  return (
    <div className="navContainer">
      <ul>
        {Object.entries(navbarContent).map(([key, value]) => (
          <li>
            <NavLink
              className="navLink"
              to={"/" + value.href}
              style={{ color: color }}
            >
              {value.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FullMenu;
