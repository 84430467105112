import React from "react";
import "../css/GlobalProjects.css";
import ProjectList from "../projects/ProjectList";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const ProjectLinks = [
  "toutes",
  "construction",
  "piscine",
  "renovation",
  "terrasse",
  "toiture",
];

const GlobalProjects = () => {
  const { category } = useParams();
  let classes = [];
  let projects = [];

  // eslint-disable-next-line
  ProjectLinks.map((value, index) => {
    if (category === value) {
      classes[index] = {
        color: "grey",
      };
    } else {
      classes[index] = {
        color: "black",
      };
    }
  });

  Object.entries(ProjectList).map(([key, value]) =>
    value.category.forEach((v, index) => {
      if (v === category) {
        projects.push(value.main);
      }
    })
  );

  return (
    <div className="globalProjectsWrapper">
      <div className="projectsChoice">
        {ProjectLinks.map((value, index) => (
          <Link
            style={classes[index]}
            className="projectsLink"
            to={"/realisations/" + value}
          >
            <p key={index}>{value}</p>
          </Link>
        ))}
      </div>
      <div className="projectImageWrapper">
        {projects.map((value, index) => (
          <div className="projectCard">
            <Link to={"/resume/" + index}>
              <img
                key={index}
                src={value}
                alt="projectImage"
                className="projectImage"
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlobalProjects;
