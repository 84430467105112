import React from "react";
import "../../css/Services.css";
import Navbar from "../layouts/Navbar";
import ServicesHeader from "../ServicesHeader";
import ServicesInfo from "../ServicesInfo";
import ProjectsBanner from "../ProjectsBanner";
import PartnersBanner from "../PartnersBanner";
import ContactUs from "../layouts/ContactUs";

const Services = () => {
  return (
    <>
      <Navbar background="white" color="black" />
      <section className="servicesContainer">
        <ServicesHeader />
        <ServicesInfo />
        <ProjectsBanner />
        <PartnersBanner />
      </section>
      <ContactUs />
    </>
  );
};

export default Services;
