import React from "react";
import "../css/ServicesInfo.css";

import Construction from "../assets/features/serv_construction.png";
import Renovation from "../assets/features/serv_renovation.png";
import Charpentes from "../assets/features/serv_charpente.png";
import Revetement from "../assets/features/serv_revetement.png";

const ServicesInfo = () => {
  return (
    <div className="servicesInfoContainer">
      <div className="servicesInfoIntro">
        <h1>
          Nous pouvons nous occuper de la totalité de votre futur chez vous.
        </h1>
        <p>
          L’entreprise Erbat met son expérience au profit de ses clients à
          travers <span>quatre</span> types de services :
        </p>
      </div>
      <div className="servicesInfoResume">
        <div className="servicesText">
          <h2>Construction</h2>
          <p>
            Notre entreprise est spécialisée dans la <span>construction</span> à
            travers divers types de biens, que ce soit les <span>villas</span>,
            les <span>terrasses</span> ou encore les
            <span> piscines</span>. Nous mettons à disposition une équipe
            expérimentée, et possédons notre propre
            <span> matériel à la pointe de la technique</span> fournie par des
            leaders du marché pour assurer dans un premier temps l’entière
            satisfaction de nos clients tout en minimisant les couts de
            production.
          </p>
        </div>
        <img src={Construction} className="servicesImage" alt="construction" />
      </div>
      <div className="servicesInfoResume reverse">
        <div className="servicesText textReverse">
          <h2>Rénovation</h2>
          <p>
            Notre savoir-faire et notre <span>expérience</span> nous permettent
            d’effectuer un travail de qualité dans la <span>rénovation</span> de
            votre bien. Ainsi notre équipe intervient pour différents types de
            projets de rénovation, que ce soit pour des
            <span> constructions récentes </span>
            ou bien pour des bâtiments plus anciens grâce à des méthodes de
            construction
            <span> traditionnelles</span> et propre à notre entreprise.
          </p>
        </div>
        <img src={Renovation} className="servicesImage" alt="renovation" />
      </div>
      <div className="servicesInfoResume">
        <div className="servicesText">
          <h2>Charpentes en bois</h2>
          <p>
            L’entreprise Erbat est également spécialisée dans la réalisation
            ainsi que la <span>rénovation</span> de charpentes en bois. Nous
            nous adaptons à tous types d’environnement et mettons toute
            <span> notre expérience à votre service</span>. Nos réalisations
            sont divers et variées en passant par les
            <span> charpentes traditionnelles, lamellé-collé</span>, ou bien les
            <span> ossatures</span> et autres types d’ouvrage en utilisant le
            <span> bois</span> comme matériau principal.
          </p>
        </div>
        <img src={Charpentes} className="servicesImage" alt="charpentes" />
      </div>
      <div className="servicesInfoResume reverse">
        <div className="servicesText textReverse">
          <h2>Revêtement de sols et muraux</h2>
          <p>
            Notre riche expérience dans le domaine du <span>revêtement</span>,
            nous permet de vous offrir des services de grande qualité en vous
            accompagner dans la <span>réalisation</span> et la pose de
            revêtements de <span>sols</span> et de <span>murs</span>. Nous
            intervenons sur <span>tous types de surface</span>, qu’il s’agisse
            d’un parquet, d’une moquette, ou d’une dalle dans le cadre des
            revêtements de sol ou bien d’un placo, d’un enduit, d’une faïence ou
            autres en ce qui concerne les <span>revêtements muraux</span>.
          </p>
        </div>
        <img src={Revetement} className="servicesImage" alt="revetement" />
      </div>
      <div className="borderLine" />
    </div>
  );
};

export default ServicesInfo;
