import React from "react";
import "../../css/ProjectDetails.css";
import Navbar from "../layouts/Navbar";
import ContactUs from "../layouts/ContactUs";
import HouseMapLocation from "../HouseMapLocation";
import ProjectImage from "../ProjectImage";
import ProjectList from "../../projects/ProjectList";
import { useParams } from "react-router-dom";

const ProjectDetails = () => {
  const { id } = useParams();
  let project = {};

  // eslint-disable-next-line
  Object.entries(ProjectList).map(([key, value]) => {
    if (id === key) {
      project = value;
    }
  });

  return (
    <>
      <Navbar background="white" color="black" />
      <section className="projectDetailsContainer">
        <div className="projectDetailsWrapper">
          <div className="detailsWrapper">
            <h1>{project.title}</h1>
            <p>{project.description}</p>
            <HouseMapLocation pos={project.pos} />
          </div>
          <img
            src={project.main}
            alt="projectImage"
            className="projectDetailsImage"
          />
        </div>
        <div className="projectDetailsImageWrapper">
          <ProjectImage projects={project.others} />
        </div>
      </section>
      <ContactUs />
    </>
  );
};

export default ProjectDetails;
