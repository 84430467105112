import React from "react";
import "../css/About.css";
import { NavLink } from "react-router-dom";

import Guarantee from "../assets/features/guarantee.png";
import GreyLogo from "../assets/logo/grey_logo.png";
import GrosOeuvre from "../assets/features/gros_oeuvre.png";
import Renovation from "../assets/features/renovation.png";
import SecondOeuvre from "../assets/features/second_oeuvre.png";
import Charpente from "../assets/features/charpente.png";

const About = () => {
  return (
    <div className="aboutContainer">
      <div className="descriptionWrapper">
        <img
          src={GreyLogo}
          className="greyLogo"
          alt="grey_logo"
          width="113px"
        />
        <div className="heroIntro">
          <text>
            L’expertise et la technologie d’<span>ERBAT</span> sont reconnues
            dans toute la région PACA. L’entreprise a été créé en
            <span> 2007</span> et se compose de <span>8 personnes</span>. La
            nature humaine de l’entreprise permet une communication constructive
            et durable avec nos clients.
          </text>
          <text>
            Le respect des <span>normes</span> actuelles des valeurs humaines et
            relationnelles, des règles de sécurité, impliquent une grande
            <span> rigueur</span> de la part de tous. Ces exigences existent à
            toutes les étapes de la production afin de <span>garantir</span> une
            qualité irréprochable pour tous nos services.
          </text>
        </div>
      </div>
      <div className="infoWrapper">
        <div className="imageWrapper">
          <img src={GrosOeuvre} alt="gros_oeuvre" className="infoLogo" />
          <img src={Renovation} alt="renovation" className="infoLogo" />
          <img src={SecondOeuvre} alt="second_oeuvre" className="infoLogo" />
          <img src={Charpente} alt="charpente" className="infoLogo" />
        </div>
        <div className="buttonWrapper">
          <NavLink to="/services">
            <button className="darkGhostBtn">En Savoir Plus</button>
          </NavLink>
        </div>
        <div className="borderLine" />
      </div>
      <div className="guaranteeWrapper">
        <h1>Nos Moyens de garantie</h1>
        <div className="guaranteeInfoWrapper">
          <img src={Guarantee} className="guaranteeLogo" alt="guarantee_logo" />
          <text>
            Une garantie décennale n’ayant jamais eu à fonctionner en
            <span> 13</span> ans. Un <span>encadrement</span> du projet, du
            début à la fin, <span>assuré</span> par un ingénieur travaux, un
            coordinateur et la <span>présence quotidienne du Gérant</span> sur
            le chantier pour optimiser la coordination des équipes et le respect
            des délais. Des <span>chefs de chantier expérimentés</span> qui
            connaissent les enjeux et les points délicats de chaque projets.
          </text>
        </div>
      </div>
    </div>
  );
};

export default About;
