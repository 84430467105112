import React from "react";
import "../css/Headers.css";

const ProjectsHeader = () => {
  return (
    <div className="headerContainer">
      <div className="headerWrapper">
        <h1>Nos Réalisations</h1>
        <p>
          Plus de <span>100</span> réalisations de construction de gros oeuvre,
          piscine, rénovation, et second oeuvre.
        </p>
      </div>
    </div>
  );
};

export default ProjectsHeader;
