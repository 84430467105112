import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../css/Navbar.css";
import BlackLogo from "../../assets/logo/black_logo.png";
import WhiteLogo from "../../assets/logo/white_logo.png";

import FullMenu from "./FullMenu";
import Hamburger from "./HamburgerMenu";

const navbarContent = {
  Home: {
    href: "",
    title: "Accueil",
    index: 1,
  },
  Service: {
    href: "services",
    title: "Services",
    index: 2,
  },
  Projects: {
    href: "realisations/toutes",
    title: "Réalisations",
    index: 3,
  },
  Contact: {
    href: "contact",
    title: "Contact",
    index: 4,
  },
};

let colorClass;

const Navbar = ({ background, color }) => {
  const [active, setActive] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 100) {
      setActive(true);
    } else setActive(false);
  });

  if (active && color === "white") {
    colorClass = {
      backgroundColor: "white",
    };
  } else {
    colorClass = {
      backgroundColor: background,
    };
  }
  return (
    <section className="navbar" style={colorClass}>
      <Link to="/">
        {color !== "white" || (color === "white" && active) ? (
          <img src={BlackLogo} alt="Navbar Logo" />
        ) : (
          <img src={WhiteLogo} alt="Navbar Logo" />
        )}
      </Link>
      <Hamburger navbarContent={navbarContent} color={color} active={active} />
      <FullMenu navbarContent={navbarContent} color={color} active={active} />
    </section>
  );
};

export default Navbar;
