import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./css/App.css";
import Contact from "./components/screens/Contact";
import Footer from "./components/layouts/Footer";
import ScrollToTop from "./components/layouts/ScrollToTop";
import HomePage from "./components/screens/HomePage";
import Services from "./components/screens/Services";
import Projects from "./components/screens/Projects";
import ProjectDetails from "./components/screens/ProjectDetails";
import Terms from "./components/screens/Terms";
import NotFound from "./components/screens/NotFound";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/realisations/:category" component={Projects} />
            <Route exact path="/resume/:id" component={ProjectDetails} />
            <Route exact path="/termesetconditions" component={Terms} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
