import React from "react";
import "../css/ProjectsBanner.css";
import InfiniteCarousel from "react-leaf-carousel";
import ProjectList from "../projects/ProjectList";

const ProjectsBanner = () => {
  return (
    <div className="projectsBannerContainer">
      <div className="projectsBannerWrapper">
        <InfiniteCarousel
          breakpoints={[
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
                showSides: true,
                sidesOpacity: 0.8,
                sideSize: 0.1,
                autoCycle: false,
                arrows: false,
                slidesSpacing: 10,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ]}
          responsive
          autoCycle={true}
          pauseOnHover
          sidesOpacity={1}
          sideSize={0.1}
          slidesToScroll={1}
          slidesToShow={3}
          scrollOnDevice={true}
        >
          {Object.entries(ProjectList).map(([key, value]) => (
            <img
              key={key}
              src={value.main}
              className="houseWrapper"
              alt="house"
            />
          ))}
        </InfiniteCarousel>
      </div>
    </div>
  );
};

export default ProjectsBanner;
