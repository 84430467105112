import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./css/index.css";
import "./fonts/Lovelo_Black.otf";
import "./fonts/OpenSans-Light.ttf";
import "./fonts/OpenSans-SemiBold.ttf";
import "./fonts/OpenSans-Bold.ttf";
import "./fonts/Montserrat-Regular.ttf";
import "./fonts/Montserrat-Light.ttf";
import "./fonts/Montserrat-SemiBold.ttf";
import "./fonts/Montserrat-Bold.ttf";
import "./fonts/Raleway-Light.ttf";
import "./fonts/Raleway-SemiBold.ttf";
import "./fonts/Raleway-Bold.ttf";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
