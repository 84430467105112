import React from "react";
import "../css/LandingPage.css";

import WhiteHousePhone from "../assets/features/white_house_phone.png";
import WhiteHouse from "../assets/features/white_house.png";

const LandingPage = () => {
  return (
    <div className="landingPageContainer">
      <div className="companyName">
        <h1>Entreprise generale</h1>
        <h1>Erbat</h1>
        <text>Depuis 2007</text>
      </div>
      <img
        src={WhiteHousePhone}
        alt="white_house"
        className="whiteHousePhone"
      />
      <img src={WhiteHouse} alt="white_house" className="whiteHouse" />
    </div>
  );
};

export default LandingPage;
