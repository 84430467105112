import React from "react";
import "../css/PartnersBanner.css";

import Chausson from "../assets/partners/chausson.png";
import Cemex from "../assets/partners/cemex.png";
import Ciffreo from "../assets/partners/ciffreo.png";
import Fimurex from "../assets/partners/fimurex.png";
import Lafarge from "../assets/partners/lafarge.png";
import PointP from "../assets/partners/point_p.png";
import Simc from "../assets/partners/simc.png";

const PartnersBanner = () => {
  return (
    <div className="partnersBannerContainer">
      <img src={Chausson} className="partnersImage" alt="chausson" />
      <img src={Cemex} className="partnersImage" alt="cemex" />
      <img src={Ciffreo} className="partnersImage" alt="ciffreo" />
      <img src={Fimurex} className="partnersImage" alt="fimurex" />
      <img src={Lafarge} className="partnersImage" alt="lafarge" />
      <img src={PointP} className="partnersImage" alt="pointP" />
      <img src={Simc} className="partnersImage" alt="simc" />
    </div>
  );
};

export default PartnersBanner;
