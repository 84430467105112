import React from "react";
import "../../css/ContactUs.css";
import { NavLink } from "react-router-dom";

const ContactUs = () => {
  return (
    <section className="contactUsContainer">
      <div className="infoWrapper">
        <p>Besoin d'informations, une demande de devis ?</p>
      </div>
      <div className="listenWrapper">
        <p>Nous sommes à votre écoute</p>
      </div>
      <div className="buttonWrapper">
        <NavLink to="/contact">
          <button className="transparentBtn">En Savoir Plus</button>
        </NavLink>
      </div>
    </section>
  );
};

export default ContactUs;
