import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import "../css/GoogleMap.css";

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

const pos = { lat: 43.27155, lng: 5.40595 };

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showInfoWindow: true,
    });
  };

  onInfoWindowClose = (props, marker, e) => {
    this.setState({
      showInfoWindow: false,
    });
  };
  render() {
    return (
      <div className="googleMapContainer">
        <div className="googleMapWrapper">
          <Map
            google={this.props.google}
            zoom={14}
            containerStyle={containerStyle}
            className="mapColor"
            initialCenter={pos}
          >
            <Marker
              onClick={this.onMarkerClick}
              name={"Erbat Entreprise Générale"}
              position={pos}
            />
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showInfoWindow}
              onClose={this.onInfoWindowClose}
            >
              <div>
                <h1>{this.state.selectedPlace.name}</h1>
              </div>
            </InfoWindow>
          </Map>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDwTiH0g45dZ2XbK7-elw7sxEbaut53QpY",
})(GoogleMap);
