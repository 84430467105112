import Allauch1 from "../assets/houses/Allauch/Maison 1/1.jpg";
import Allauch2 from "../assets/houses/Allauch/Maison 2/1.jpg";
import Allauch2_2 from "../assets/houses/Allauch/Maison 2/2.jpg";
import Allauch2_3 from "../assets/houses/Allauch/Maison 2/3.jpg";
import Allauch2_4 from "../assets/houses/Allauch/Maison 2/4.jpg";
import Allauch2_5 from "../assets/houses/Allauch/Maison 2/5.jpg";
import Allauch2_6 from "../assets/houses/Allauch/Maison 2/6.jpg";
import Allauch2_7 from "../assets/houses/Allauch/Maison 2/7.jpg";
import Allauch2_8 from "../assets/houses/Allauch/Maison 2/8.jpg";
import Allauch2_9 from "../assets/houses/Allauch/Maison 2/9.jpg";
import Auriol from "../assets/houses/Auriol/1.jpg";
import Auriol_2 from "../assets/houses/Auriol/2.jpg";
import Auriol_3 from "../assets/houses/Auriol/3.jpg";
import Auriol_4 from "../assets/houses/Auriol/4.jpg";
import Auriol_5 from "../assets/houses/Auriol/5.jpg";
import Belair1 from "../assets/houses/Belair/Maison 1/0.jpg";
import Belair1_1 from "../assets/houses/Belair/Maison 1/1.jpg";
import Belair1_2 from "../assets/houses/Belair/Maison 1/2.jpg";
import Belair1_3 from "../assets/houses/Belair/Maison 1/3.jpg";
import Belair1_4 from "../assets/houses/Belair/Maison 1/4.jpg";
import Belair1_5 from "../assets/houses/Belair/Maison 1/5.jpg";
import Belair1_6 from "../assets/houses/Belair/Maison 1/6.jpg";
import Belair2 from "../assets/houses/Belair/Maison 2/1.jpg";
import Belair2_2 from "../assets/houses/Belair/Maison 2/2.jpg";
import Belair2_3 from "../assets/houses/Belair/Maison 2/3.jpg";
import Belair2_4 from "../assets/houses/Belair/Maison 2/4.jpg";
import Belair2_5 from "../assets/houses/Belair/Maison 2/5.jpg";
import Belair2_6 from "../assets/houses/Belair/Maison 2/6.jpg";
import Belair2_7 from "../assets/houses/Belair/Maison 2/7.jpg";
import Belair2_8 from "../assets/houses/Belair/Maison 2/8.jpg";
import Belair2_9 from "../assets/houses/Belair/Maison 2/9.jpg";
import Belair2_10 from "../assets/houses/Belair/Maison 2/10.jpg";
import Belair2_11 from "../assets/houses/Belair/Maison 2/11.jpg";
import Belair2_12 from "../assets/houses/Belair/Maison 2/12.jpg";
import Belair2_13 from "../assets/houses/Belair/Maison 2/13.jpg";
import Belair2_14 from "../assets/houses/Belair/Maison 2/14.jpg";
import Belair2_15 from "../assets/houses/Belair/Maison 2/15.jpg";
import Belair2_16 from "../assets/houses/Belair/Maison 2/16.jpg";
import Belair2_17 from "../assets/houses/Belair/Maison 2/17.jpg";
import Belair2_18 from "../assets/houses/Belair/Maison 2/18.jpg";
import Belair2_19 from "../assets/houses/Belair/Maison 2/19.jpg";
import Belair2_20 from "../assets/houses/Belair/Maison 2/20.jpg";
import Belair2_21 from "../assets/houses/Belair/Maison 2/21.jpg";
import Belair2_22 from "../assets/houses/Belair/Maison 2/22.jpg";
import Belair2_23 from "../assets/houses/Belair/Maison 2/23.jpg";
import Belair2_24 from "../assets/houses/Belair/Maison 2/24.jpg";
import Belair2_25 from "../assets/houses/Belair/Maison 2/25.jpg";
import Belair2_26 from "../assets/houses/Belair/Maison 2/26.jpg";
import Cassis from "../assets/houses/Cassis/1.jpg";
import Cassis_2 from "../assets/houses/Cassis/2.jpg";
import Cassis_3 from "../assets/houses/Cassis/3.jpg";
import Cassis_4 from "../assets/houses/Cassis/4.jpg";
import Cassis_5 from "../assets/houses/Cassis/5.jpg";
import Cassis_6 from "../assets/houses/Cassis/6.jpg";
import Cassis_7 from "../assets/houses/Cassis/7.jpg";
import Cassis_8 from "../assets/houses/Cassis/8.jpg";
import Cassis_9 from "../assets/houses/Cassis/9.jpg";
import Cassis_10 from "../assets/houses/Cassis/10.jpg";
import Cassis_11 from "../assets/houses/Cassis/11.jpg";
import Cassis_12 from "../assets/houses/Cassis/12.jpg";
import Cassis_13 from "../assets/houses/Cassis/13.jpg";
import Cassis_14 from "../assets/houses/Cassis/14.jpg";
import Cassis_15 from "../assets/houses/Cassis/15.jpg";
import Cassis_16 from "../assets/houses/Cassis/16.jpg";
import Cassis_17 from "../assets/houses/Cassis/17.jpg";
import Cassis_18 from "../assets/houses/Cassis/18.jpg";
import Cassis_19 from "../assets/houses/Cassis/19.jpg";
import Cassis_20 from "../assets/houses/Cassis/20.jpg";
import ChateauGombert from "../assets/houses/Chateau gombert/1.jpg";
import ChateauGombert_2 from "../assets/houses/Chateau gombert/2.jpg";
import ChateauGombert_3 from "../assets/houses/Chateau gombert/3.jpg";
import ChateauGombert_4 from "../assets/houses/Chateau gombert/4.jpg";
import ChateauGombert_5 from "../assets/houses/Chateau gombert/5.jpg";
import ChateauGombert_6 from "../assets/houses/Chateau gombert/6.jpg";
import ChateauGombert_7 from "../assets/houses/Chateau gombert/7.jpg";
import ChateauGombert_8 from "../assets/houses/Chateau gombert/8.jpg";
import ChateauGombert_9 from "../assets/houses/Chateau gombert/9.jpg";
import ChateauGombert_10 from "../assets/houses/Chateau gombert/10.jpg";
import ChateauGombert_11 from "../assets/houses/Chateau gombert/11.jpg";
import ChateauGombert_12 from "../assets/houses/Chateau gombert/12.jpg";
import ChateauGombert_13 from "../assets/houses/Chateau gombert/13.jpg";
import ChateauGombert_14 from "../assets/houses/Chateau gombert/14.jpg";
import ChateauGombert_15 from "../assets/houses/Chateau gombert/15.jpg";
import ChateauGombert_16 from "../assets/houses/Chateau gombert/16.jpg";
import Eoures1 from "../assets/houses/Eoures Village/Maison 1/1.jpg";
import Eoures1_2 from "../assets/houses/Eoures Village/Maison 1/2.jpg";
import Eoures1_3 from "../assets/houses/Eoures Village/Maison 1/3.jpg";
import Eoures1_4 from "../assets/houses/Eoures Village/Maison 1/4.jpg";
import Eoures2 from "../assets/houses/Eoures Village/Maison 2/1.jpg";
import Eoures2_2 from "../assets/houses/Eoures Village/Maison 2/2.jpg";
import Eoures2_3 from "../assets/houses/Eoures Village/Maison 2/3.jpg";
import Eoures2_4 from "../assets/houses/Eoures Village/Maison 2/4.jpg";
import Eoures2_5 from "../assets/houses/Eoures Village/Maison 2/5.jpg";
import Eoures2_6 from "../assets/houses/Eoures Village/Maison 2/6.jpg";
import Eoures2_7 from "../assets/houses/Eoures Village/Maison 2/7.jpg";
import Eoures2_8 from "../assets/houses/Eoures Village/Maison 2/8.jpg";
import Eoures2_9 from "../assets/houses/Eoures Village/Maison 2/9.jpg";
import Eoures2_10 from "../assets/houses/Eoures Village/Maison 2/10.jpg";
import Eoures2_11 from "../assets/houses/Eoures Village/Maison 2/11.jpg";
import Eoures2_12 from "../assets/houses/Eoures Village/Maison 2/12.jpg";
import Eoures2_13 from "../assets/houses/Eoures Village/Maison 2/13.jpg";
import Eoures2_14 from "../assets/houses/Eoures Village/Maison 2/14.jpg";
import Eoures2_15 from "../assets/houses/Eoures Village/Maison 2/15.jpg";
import Eoures2_16 from "../assets/houses/Eoures Village/Maison 2/16.jpg";
import Eoures2_17 from "../assets/houses/Eoures Village/Maison 2/17.jpg";
import Eoures2_18 from "../assets/houses/Eoures Village/Maison 2/18.jpg";
import Eoures2_19 from "../assets/houses/Eoures Village/Maison 2/19.jpg";
import LanconDeProvence from "../assets/houses/Lancon de provence/1.jpg";
import LanconDeProvence_2 from "../assets/houses/Lancon de provence/2.jpg";
import LanconDeProvence_3 from "../assets/houses/Lancon de provence/3.jpg";
import LanconDeProvence_4 from "../assets/houses/Lancon de provence/4.jpg";
import LanconDeProvence_5 from "../assets/houses/Lancon de provence/5.jpg";
import Marseille11 from "../assets/houses/Marseille 11/1.jpg";
import Marseille11_2 from "../assets/houses/Marseille 11/2.jpg";
import Marseille11_3 from "../assets/houses/Marseille 11/3.jpg";
import Pertuis from "../assets/houses/Pertuis/1.jpg";
import Pertuis_2 from "../assets/houses/Pertuis/2.jpg";
import Pertuis_3 from "../assets/houses/Pertuis/3.jpg";
import PointeRouge from "../assets/houses/Pointe rouge/1.jpg";
import PointeRouge_2 from "../assets/houses/Pointe rouge/2.jpg";
import PointeRouge_3 from "../assets/houses/Pointe rouge/3.jpg";
import PointeRouge_4 from "../assets/houses/Pointe rouge/4.jpg";
import PointeRouge_5 from "../assets/houses/Pointe rouge/5.jpg";
import PointeRouge_6 from "../assets/houses/Pointe rouge/6.jpg";
import PointeRouge_7 from "../assets/houses/Pointe rouge/7.jpg";
import PointeRouge_8 from "../assets/houses/Pointe rouge/8.jpg";
import PointeRouge_9 from "../assets/houses/Pointe rouge/9.jpg";
import PointeRouge_10 from "../assets/houses/Pointe rouge/10.jpg";
import PointeRouge_11 from "../assets/houses/Pointe rouge/11.jpg";
import PointeRouge_12 from "../assets/houses/Pointe rouge/12.jpg";
import PointeRouge_13 from "../assets/houses/Pointe rouge/13.jpg";
import PointeRouge_14 from "../assets/houses/Pointe rouge/14.jpg";
import PointeRouge_15 from "../assets/houses/Pointe rouge/15.jpg";
import Rougier from "../assets/houses/Rougier/1.jpg";
import SaintZackarie from "../assets/houses/Saint Zackarie/1.jpg";
import SaintZackarie_2 from "../assets/houses/Saint Zackarie/2.jpg";
import SaintZackarie_3 from "../assets/houses/Saint Zackarie/3.jpg";
import SaintZackarie_4 from "../assets/houses/Saint Zackarie/4.jpg";
import SaintZackarie_5 from "../assets/houses/Saint Zackarie/5.jpg";
import SalonDeProvence1 from "../assets/houses/Salon de provence/Maison 1/0.jpg";
import SalonDeProvence1_1 from "../assets/houses/Salon de provence/Maison 1/1.jpg";
import SalonDeProvence1_2 from "../assets/houses/Salon de provence/Maison 1/2.jpg";
import SalonDeProvence1_3 from "../assets/houses/Salon de provence/Maison 1/3.jpg";
import SalonDeProvence1_4 from "../assets/houses/Salon de provence/Maison 1/4.jpg";
import SalonDeProvence1_5 from "../assets/houses/Salon de provence/Maison 1/5.jpg";
import SalonDeProvence1_6 from "../assets/houses/Salon de provence/Maison 1/6.jpg";
import SalonDeProvence1_7 from "../assets/houses/Salon de provence/Maison 1/7.jpg";
import SalonDeProvence1_8 from "../assets/houses/Salon de provence/Maison 1/8.jpg";
import SalonDeProvence1_9 from "../assets/houses/Salon de provence/Maison 1/9.jpg";
import SalonDeProvence1_10 from "../assets/houses/Salon de provence/Maison 1/10.jpg";
import SalonDeProvence1_11 from "../assets/houses/Salon de provence/Maison 1/11.jpg";
import SalonDeProvence1_12 from "../assets/houses/Salon de provence/Maison 1/12.jpg";
import SalonDeProvence1_13 from "../assets/houses/Salon de provence/Maison 1/13.jpg";
import SalonDeProvence2 from "../assets/houses/Salon de provence/Maison 2/1.jpg";
import SalonDeProvence2_2 from "../assets/houses/Salon de provence/Maison 2/2.jpg";
import SalonDeProvence2_3 from "../assets/houses/Salon de provence/Maison 2/3.jpg";
import SalonDeProvence2_4 from "../assets/houses/Salon de provence/Maison 2/4.jpg";
import SalonDeProvence2_5 from "../assets/houses/Salon de provence/Maison 2/5.jpg";
import SalonDeProvence2_6 from "../assets/houses/Salon de provence/Maison 2/6.jpg";
import SalonDeProvence2_7 from "../assets/houses/Salon de provence/Maison 2/7.jpg";
import SalonDeProvence2_8 from "../assets/houses/Salon de provence/Maison 2/8.jpg";
import SalonDeProvence2_9 from "../assets/houses/Salon de provence/Maison 2/9.jpg";
import SalonDeProvence2_10 from "../assets/houses/Salon de provence/Maison 2/10.jpg";
import SalonDeProvence3 from "../assets/houses/Salon de provence/Maison 3/1.jpg";
import SalonDeProvence3_2 from "../assets/houses/Salon de provence/Maison 3/2.jpg";
import SalonDeProvence3_3 from "../assets/houses/Salon de provence/Maison 3/3.jpg";
import SalonDeProvence3_4 from "../assets/houses/Salon de provence/Maison 3/4.jpg";
import SalonDeProvence4 from "../assets/houses/Salon de provence/Maison4/1.jpg";
import SalonDeProvence4_2 from "../assets/houses/Salon de provence/Maison4/2.jpg";
import SalonDeProvence4_3 from "../assets/houses/Salon de provence/Maison4/3.jpg";
import Sanary from "../assets/houses/Sanary/1.jpg";
import Sanary_2 from "../assets/houses/Sanary/2.jpg";
import Sanary_3 from "../assets/houses/Sanary/3.jpg";
import Sanary_4 from "../assets/houses/Sanary/4.jpg";
import Sanary_5 from "../assets/houses/Sanary/5.jpg";
import Sanary_6 from "../assets/houses/Sanary/6.jpg";
import Sanary_7 from "../assets/houses/Sanary/7.jpg";
import Sanary_8 from "../assets/houses/Sanary/8.jpg";
import Sanary_9 from "../assets/houses/Sanary/9.jpg";
import Sanary_10 from "../assets/houses/Sanary/10.jpg";
import Sanary_11 from "../assets/houses/Sanary/11.jpg";
import Sanary_12 from "../assets/houses/Sanary/12.jpg";
import Valentine from "../assets/houses/Valentine/1.jpg";
import Valentine_2 from "../assets/houses/Valentine/2.jpg";
import Valentine_3 from "../assets/houses/Valentine/3.jpg";
import Valentine_4 from "../assets/houses/Valentine/4.jpg";
import Valentine_5 from "../assets/houses/Valentine/5.jpg";
import Valentine_6 from "../assets/houses/Valentine/6.jpg";
import Valentine_7 from "../assets/houses/Valentine/7.jpg";
import Valentine_8 from "../assets/houses/Valentine/8.jpg";
import Valentine_9 from "../assets/houses/Valentine/9.jpg";
import Valentine_10 from "../assets/houses/Valentine/10.jpg";
import Valentine_11 from "../assets/houses/Valentine/11.jpg";
import Valentine_12 from "../assets/houses/Valentine/12.jpg";
import Valentine_13 from "../assets/houses/Valentine/13.jpg";
import Valentine_14 from "../assets/houses/Valentine/14.jpg";
import Valentine_15 from "../assets/houses/Valentine/15.jpg";
import Valentine_16 from "../assets/houses/Valentine/16.jpg";
import Valentine_17 from "../assets/houses/Valentine/17.jpg";
import Valentine_18 from "../assets/houses/Valentine/18.jpg";
import Valentine_19 from "../assets/houses/Valentine/19.jpg";
import Valentine_20 from "../assets/houses/Valentine/20.jpg";
import Vitrolles from "../assets/houses/Vitrolles/1.jpg";
import Vitrolles_2 from "../assets/houses/Vitrolles/2.jpg";
import Vitrolles_3 from "../assets/houses/Vitrolles/3.jpg";
import Vitrolles_4 from "../assets/houses/Vitrolles/4.jpg";
import Vitrolles_5 from "../assets/houses/Vitrolles/5.jpg";
import Vitrolles_6 from "../assets/houses/Vitrolles/6.jpg";
import Vitrolles_7 from "../assets/houses/Vitrolles/7.jpg";

const ProjectList = {
  0: {
    title: "Villa Allauch 2",
    description: "Maison neuve de plein pied",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 43.33608,
      lng: 5.48203,
    },
    main: Allauch2,
    others: [
      Allauch2_2,
      Allauch2_3,
      Allauch2_4,
      Allauch2_5,
      Allauch2_6,
      Allauch2_7,
      Allauch2_8,
      Allauch2_9,
    ],
  },
  1: {
    title: "Villa Bel-air 1",
    description: "Magnifique villa de plein pied avec piscine",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 39.53567,
      lng: -76.34847,
    },
    main: Belair1,
    others: [Belair1_1, Belair1_2, Belair1_3, Belair1_4, Belair1_5, Belair1_6],
  },
  2: {
    title: "Villa Bel-air 2",
    description: "Maison avec étage d’architecte avec piscine",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 39.53567,
      lng: -76.34847,
    },
    main: Belair2,
    others: [
      Belair2_2,
      Belair2_3,
      Belair2_4,
      Belair2_5,
      Belair2_6,
      Belair2_7,
      Belair2_8,
      Belair2_9,
      Belair2_10,
      Belair2_11,
      Belair2_12,
      Belair2_13,
      Belair2_14,
      Belair2_15,
      Belair2_16,
      Belair2_17,
      Belair2_18,
      Belair2_19,
      Belair2_20,
      Belair2_21,
      Belair2_22,
      Belair2_23,
      Belair2_24,
      Belair2_25,
      Belair2_26,
    ],
  },
  3: {
    title: "Cassis",
    description: "Chantier en cours de construction",
    category: ["toutes", "construction", "toiture"],
    pos: {
      lat: 43.216068,
      lng: 5.53855,
    },
    main: Cassis,
    others: [
      Cassis_2,
      Cassis_3,
      Cassis_4,
      Cassis_5,
      Cassis_6,
      Cassis_7,
      Cassis_8,
      Cassis_9,
      Cassis_10,
      Cassis_11,
      Cassis_12,
      Cassis_13,
      Cassis_14,
      Cassis_15,
      Cassis_16,
      Cassis_17,
      Cassis_18,
      Cassis_19,
      Cassis_20,
    ],
  },
  4: {
    title: "Château Gombert",
    description: "Maison familiale avec un étage",
    category: ["toutes", "construction", "toiture"],
    pos: {
      lat: 43.354836,
      lng: 5.444596,
    },
    main: ChateauGombert,
    others: [
      ChateauGombert_2,
      ChateauGombert_3,
      ChateauGombert_4,
      ChateauGombert_5,
      ChateauGombert_6,
      ChateauGombert_7,
      ChateauGombert_8,
      ChateauGombert_9,
      ChateauGombert_10,
      ChateauGombert_11,
      ChateauGombert_12,
      ChateauGombert_13,
      ChateauGombert_14,
      ChateauGombert_15,
      ChateauGombert_16,
    ],
  },
  5: {
    title: "Beautiful 11 e",
    description: "Incroyable maison avec son étage. piscine splendide",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 43.290252,
      lng: 5.441945,
    },
    main: Eoures2,
    others: [
      Eoures2_2,
      Eoures2_3,
      Eoures2_4,
      Eoures2_5,
      Eoures2_6,
      Eoures2_7,
      Eoures2_8,
      Eoures2_9,
      Eoures2_10,
      Eoures2_11,
      Eoures2_12,
      Eoures2_13,
      Eoures2_14,
      Eoures2_15,
      Eoures2_16,
      Eoures2_17,
      Eoures2_18,
      Eoures2_19,
    ],
  },
  6: {
    title: "Rénovation 13",
    description: "Extension d’une maison de 100 m2 avec toit-terrasse",
    category: ["toutes", "renovation", "terrasse", "toiture"],
    pos: {
      lat: 43.243637,
      lng: 5.370123,
    },
    main: PointeRouge,
    others: [
      PointeRouge_2,
      PointeRouge_3,
      PointeRouge_4,
      PointeRouge_5,
      PointeRouge_6,
      PointeRouge_7,
      PointeRouge_8,
      PointeRouge_9,
      PointeRouge_10,
      PointeRouge_11,
      PointeRouge_12,
      PointeRouge_13,
      PointeRouge_14,
      PointeRouge_15,
    ],
  },
  7: {
    title: "Maison 13330",
    description: "Belle maison avec un aménagement extérieur splendide",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 43.623242,
      lng: 5.208563,
    },
    main: SalonDeProvence1,
    others: [
      SalonDeProvence1_1,
      SalonDeProvence1_2,
      SalonDeProvence1_3,
      SalonDeProvence1_4,
      SalonDeProvence1_5,
      SalonDeProvence1_6,
      SalonDeProvence1_7,
      SalonDeProvence1_8,
      SalonDeProvence1_9,
      SalonDeProvence1_10,
      SalonDeProvence1_11,
      SalonDeProvence1_12,
      SalonDeProvence1_13,
    ],
  },
  8: {
    title: "Villa 13330",
    description: "Incroyable maison avec une belle piscine",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 43.623242,
      lng: 5.208563,
    },
    main: SalonDeProvence2,
    others: [
      SalonDeProvence2_2,
      SalonDeProvence2_3,
      SalonDeProvence2_4,
      SalonDeProvence2_5,
      SalonDeProvence2_6,
      SalonDeProvence2_7,
      SalonDeProvence2_8,
      SalonDeProvence2_9,
      SalonDeProvence2_10,
    ],
  },
  9: {
    title: "Sanary",
    description: "Rénovation complète d’une maison de ville",
    category: ["toutes", "renovation"],
    pos: {
      lat: 43.120999,
      lng: 5.804578,
    },
    main: Sanary,
    others: [
      Sanary_2,
      Sanary_3,
      Sanary_4,
      Sanary_5,
      Sanary_6,
      Sanary_7,
      Sanary_8,
      Sanary_9,
      Sanary_10,
      Sanary_11,
      Sanary_12,
    ],
  },
  10: {
    title: "Splendide 11e",
    description: "Magnifique maison de 520 m2 avec une piscine éblouissante",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 43.293143,
      lng: 5.481046,
    },
    main: Valentine,
    others: [
      Valentine_2,
      Valentine_3,
      Valentine_4,
      Valentine_5,
      Valentine_6,
      Valentine_7,
      Valentine_8,
      Valentine_9,
      Valentine_10,
      Valentine_11,
      Valentine_12,
      Valentine_13,
      Valentine_14,
      Valentine_15,
      Valentine_16,
      Valentine_17,
      Valentine_18,
      Valentine_19,
      Valentine_20,
    ],
  },
  11: {
    title: "Vitrolle",
    description: "Maison plein pied de village",
    category: ["toutes", "construction", "toiture"],
    pos: {
      lat: 43.429194,
      lng: 5.267697,
    },
    main: Vitrolles,
    others: [
      Vitrolles_2,
      Vitrolles_3,
      Vitrolles_4,
      Vitrolles_5,
      Vitrolles_6,
      Vitrolles_7,
    ],
  },
  12: {
    title: "Villa 13390",
    description: "Villa avec piscine",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 43.367801,
      lng: 5.649961,
    },
    main: Auriol,
    others: [Auriol_2, Auriol_3, Auriol_4, Auriol_5],
  },
  13: {
    title: "Maison 83",
    description: "Extension de maison avec terrasse et piscine",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 43.385106,
      lng: 5.699695,
    },
    main: SaintZackarie,
    others: [
      SaintZackarie_2,
      SaintZackarie_3,
      SaintZackarie_4,
      SaintZackarie_5,
    ],
  },
  14: {
    title: "Piscine 13330",
    description: "Création d’une piscine avec sa terrasse",
    category: ["toutes", "piscine"],
    pos: {
      lat: 43.623242,
      lng: 5.208563,
    },
    main: LanconDeProvence,
    others: [
      LanconDeProvence_2,
      LanconDeProvence_3,
      LanconDeProvence_4,
      LanconDeProvence_5,
    ],
  },
  15: {
    title: "Maison 11e",
    description: "Maison d’architecte avec bardage en bois",
    category: ["toutes", "construction", "toiture"],
    pos: {
      lat: 43.293143,
      lng: 5.481046,
    },
    main: Eoures1,
    others: [Eoures1_2, Eoures1_3, Eoures1_4],
  },
  16: {
    title: "Terrasse",
    description: "Création d’une terrasse autour de la maison",
    category: ["toutes", "renovation"],
    pos: {
      lat: 43.62878,
      lng: 5.112524,
    },
    main: SalonDeProvence3,
    others: [SalonDeProvence3_2, SalonDeProvence3_3, SalonDeProvence3_4],
  },
  17: {
    title: "Piscine 11e",
    description: "Création d’une piscine avec sa terrasse",
    category: ["toutes", "piscine"],
    pos: {
      lat: 43.293143,
      lng: 5.481046,
    },
    main: Marseille11,
    others: [Marseille11_2, Marseille11_3],
  },
  18: {
    title: "Clôture 84",
    description: "Création d’une clôture de 300 m3",
    category: ["toutes", "renovation"],
    pos: {
      lat: 43.695427,
      lng: 5.505081,
    },
    main: Pertuis,
    others: [Pertuis_2, Pertuis_3],
  },
  19: {
    title: "Piscine Salon",
    description: "Création d’une piscine avec aménagement extérieur",
    category: ["toutes", "piscine", "terrasse"],
    pos: {
      lat: 43.62878,
      lng: 5.112524,
    },
    main: SalonDeProvence4,
    others: [SalonDeProvence4_2, SalonDeProvence4_3],
  },
  20: {
    title: "Villa Allauch 1",
    description: "Incroyable villa avec une belle piscine",
    category: ["toutes", "construction", "piscine", "terrasse", "toiture"],
    pos: {
      lat: 43.33503,
      lng: 5.470239,
    },
    main: Allauch1,
    others: [],
  },
  21: {
    title: "Maison Rougiers",
    description: "Réalisation toiture",
    category: ["toutes", "construction", "toiture"],
    pos: {
      lat: 43.392398,
      lng: 5.851112,
    },
    main: Rougier,
    others: [],
  },
};

export default ProjectList;
