import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import HamburgerMenu from "react-hamburger-menu";

import "../../css/HamburgerMenu.css";

const MenuItem = (props) => {
  return (
    <div className="item" style={{ animationDelay: props.delay }}>
      <div className="buttonStyle" style={{ animationDelay: props.delay }}>
        <NavLink className="navLink" to={"/" + props.children.href}>
          {props.children.title}
        </NavLink>
      </div>
      {props.children.index !== 4 && (
        <div className="line" style={{ animationDelay: props.delay }} />
      )}
    </div>
  );
};

const Hamburger = ({ navbarContent, color, active }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleLinkClick = () => {
    setOpen(false);
  };

  if (active) {
    color = "black";
  }

  const items = Object.entries(navbarContent).map(([key, value]) => {
    return (
      <MenuItem
        key={value.index}
        delay={`${value.index * 0.1}s`}
        onClick={() => handleLinkClick()}
      >
        {value}
      </MenuItem>
    );
  });

  return (
    <div className="burgerContainer">
      <HamburgerMenu
        isOpen={open}
        menuClicked={handleClick}
        width={30}
        height={20}
        strokeWidth={3}
        animationDuration={0.3}
        color={color}
      />
      {open && <div className="burgerMenu">{items}</div>}
    </div>
  );
};

export default Hamburger;
