import React from "react";
import { Link } from "react-router-dom";
import "../../css/Footer.css";
import Logo from "../../assets/logo/footer_logo.png";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footerWrapper">
        <Link to="/" className="logoWrapper">
          <img src={Logo} alt="Footer Logo" />
        </Link>
        <div className="footerContactWrapper">
          <p>38 boulevard Dromel</p>
          <p>13009 Marseille</p>
          <p style={{ fontFamily: "OpenSans-SemiBold" }}>
            06 24 90 37 31 - 04 65 85 74 42
          </p>
          <p>ent.erbat@gmail.com</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
