import React from "react";
import "../css/ProjectImage.css";

const ProjectImage = ({ projects }) => {
  return (
    <div className="projectImageWrapper">
      {projects.map((value, index) => (
        <div className="projectCard">
          <img
            key={index}
            src={value}
            alt="projectImage"
            className="projectImage"
          />
        </div>
      ))}
    </div>
  );
};

export default ProjectImage;
