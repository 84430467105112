import React from "react";
import "../../css/NotFound.css";
import Navbar from "../layouts/Navbar";

const NotFound = () => {
  return (
    <>
      <Navbar background="white" color="black" />
      <section className="notFoundContainer">
        <div className="notFoundWrapper">
          <h1>Page Introuvable</h1>
          <p>
            La page demandée est introuvable. Veuillez utiliser la barre de
            navigation ci-dessus pour naviguer sur notre site.
          </p>
        </div>
      </section>
    </>
  );
};

export default NotFound;
