import React from "react";
import emailjs from "emailjs-com";
import { TextField, Checkbox } from "@material-ui/core";
import { useFormik } from "formik";
import {
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from "@material-ui/icons";
import "../../css/Contact.css";

import CinderBlock from "../../assets/features/cinderblock.png";

import Navbar from "../layouts/Navbar";
import GoogleMap from "../GoogleMap";

const Contact = () => {
  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "Nom requis";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Doit contenir 15 caractères ou moins";
    }

    if (!values.lastName) {
      errors.lastName = "Prénom requis";
    } else if (values.lastName.length > 15) {
      errors.lastName = "Doit contenir 15 caractères ou moins";
    }

    if (!values.email) {
      errors.email = "Email Requis";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Adresse email invalide";
    }

    if (!values.phone) {
      errors.phone = "Téléphone Requis";
    } else if (
      !/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
        values.phone
      )
    ) {
      errors.phone = "Numéro de téléphone invalide";
    }

    if (!values.subject) {
      errors.subject = "Champs requis";
    } else if (values.subject.length > 25) {
      errors.subject = "Doit contenir 25 caractères ou moins";
    }

    if (!values.description) {
      errors.description = "Champs requis";
    }

    if (!values.acceptTerms) {
      errors.acceptTerms = "Veuillez accepter les termes et conditions";
    }

    return errors;
  };

  const userID = "user_4K8H0HmOl2QRJdh9fMGKY";

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      subject: "",
      description: "",
      acceptTerms: false,
    },
    validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        await emailjs.send("mailjet", "template_KDYEBpaV", values, userID);
        alert("Message envoyé.");
        resetForm({ values: "" });
      } catch (e) {
        alert("Échec d'envoi du message.");
      }
    },
  });

  return (
    <>
      <Navbar background="white" color="black" />
      <section className="contactContainer">
        <GoogleMap />
        <div className="contactWrapper">
          <form
            onSubmit={formik.handleSubmit}
            className="formContainer"
            autoComplete="off"
          >
            <div className="upContainer">
              <TextField
                id="lastName"
                label="Votre Nom"
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : null
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                fullWidth
                className="inputField"
              />
              <TextField
                id="firstName"
                label="Votre Prénom"
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : null
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                fullWidth
                className="inputField"
              />
            </div>
            <div className="downContainer">
              <TextField
                id="phone"
                label="Numéro de téléphone"
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={
                  formik.touched.phone && formik.errors.phone
                    ? formik.errors.phone
                    : null
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                fullWidth
                className="inputField"
              />
              <TextField
                id="email"
                label="Email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                fullWidth
                className="inputField"
              />
            </div>
            <div className="downContainer">
              <TextField
                id="subject"
                label="Sujet"
                fullWidth
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={
                  formik.touched.subject && formik.errors.subject
                    ? formik.errors.subject
                    : null
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subject}
                className="inputField"
              />
            </div>
            <div className="downContainer">
              <TextField
                id="description"
                label="Description"
                multiline
                rows={4}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : null
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                className="largeInputField"
              />
            </div>
            <div className="checkBoxField">
              <Checkbox
                id="acceptTerms"
                required
                onChange={formik.handleChange}
                checked={formik.values.acceptTerms}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon style={{ color: "black" }} />}
              />
              <p>
                J'accepte les{" "}
                <a
                  className="termsLink"
                  href="/termesetconditions"
                  target="_blank"
                >
                  termes
                </a>{" "}
                et
                <a
                  className="termsLink"
                  href="/termesetconditions"
                  target="_blank"
                >
                  {" "}
                  conditions
                </a>
              </p>
            </div>
            <div className="buttonField">
              <button type="submit" className="darkBtn">
                Envoyer le message
              </button>
            </div>
          </form>
          <div className="locationWrapper">
            <h1>Adresse</h1>
            <p>38 boulevard Dromel</p>
            <p>13009 Marseille</p>
            <h1>Numero</h1>
            <p>06 24 90 37 31</p>
            <p>04 65 85 74 42</p>
            <h1>Email</h1>
            <p>ent.erbat@gmail.com</p>
          </div>
          <div className="cinderBlockWrapper">
            <img src={CinderBlock} alt="bubbleshadow" className="cinderBlock" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
