import React from "react";
import "../css/Headers.css";

const ServicesHeader = () => {
  return (
    <div className="headerContainer">
      <div className="headerWrapper">
        <h1>Nos Services</h1>
        <p>
          Nous proposons une <span>vingtaine</span> de prestations dans tous les
          domaines de la construction
        </p>
      </div>
    </div>
  );
};

export default ServicesHeader;
