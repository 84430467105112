import React from "react";
import "../../css/HomePage.css";
import Navbar from "../layouts/Navbar";
import About from "../About";
import LandingPage from "../LandingPage";
import ProjectsBanner from "../ProjectsBanner";
import ContactUs from "../layouts/ContactUs";

const HomePage = () => {
  return (
    <>
      <Navbar background="rgba(255,255,255,0)" color="white" />
      <section className="homePageContainer">
        <LandingPage />
        <About />
        <ProjectsBanner />
      </section>
      <ContactUs />
    </>
  );
};

export default HomePage;
