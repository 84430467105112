import React, { Component } from "react";
import { Map, Circle, GoogleApiWrapper } from "google-maps-react";
import "../css/HouseMapLocation.css";

class HouseMapLocation extends Component {
  render() {
    return (
      <Map
        initialCenter={this.props.pos}
        google={this.props.google}
        className="houseLocationWrapper"
        zoom={13}
      >
        <Circle
          radius={700}
          center={this.props.pos}
          strokeColor="transparent"
          strokeOpacity={0}
          strokeWeight={5}
          fillColor="#FF0000"
          fillOpacity={0.2}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDwTiH0g45dZ2XbK7-elw7sxEbaut53QpY",
})(HouseMapLocation);
