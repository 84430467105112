import React from "react";
import "../../css/Projects.css";
import Navbar from "../layouts/Navbar";
import ProjectsHeader from "../ProjectsHeader";
import GlobalProjects from "../GlobalProjects";
import ContactUs from "../layouts/ContactUs";

const Projects = () => {
  return (
    <>
      <Navbar background="white" color="black" />
      <section className="projectsContainer">
        <ProjectsHeader />
        <GlobalProjects />
      </section>
      <ContactUs />
    </>
  );
};

export default Projects;
